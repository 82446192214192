/* You can add global styles to this file, and also import other style files */
/* CSS Document *//* CSS Document */


$primary-color: #D30169;
$sub-color:   #1C004F; 
$tab-border:  #d45500;
$fade-border: #d9d9d9;
$typical-color: #ddd;
$base-color:    #ffffff;
$footer-misc:   #bbf2f5;


html {
   position:relative;                      /*This is so my footer will go to the bottom but not be fixed*/
   min-height: 100%;
   margin: 0;
   
  }


  html,body {
    margin:0;
    padding:0;
    
    overflow-x:hidden;
    }

  .test{
    position:relative;                      /*This is so my footer will go to the bottom but not be fixed*/
    min-height: 100%;
    margin: 0;
    margin-bottom: -50px;



  }

  .test2{

    margin:0 auto;
  }

body {
   margin: 0 0 250px; /* bottom = footer height*/
   }

   
     
   
.custom-color  li a{
     color: #340EF5;
} 

 
a{
  text-decoration: none !important;
}


/*************************Visibly Hidden***************************/




/***********************************************************/

img{
border:0;

}

.fa-heart{
color: $primary-color;
cursor:pointer;


}


.shacker:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.navbar-custom {
    background-image: linear-gradient( #f6eee0, white);
    }

 .grad1 {
  height: 200px;
  background-image: linear-gradient( #f6eee0, white);
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
   
    color:#1C004F;
}


.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color:#0000ff;
}

.navbar-nav > li {
  float: left;
  position: relative;
}

/*This adds underline to navbar active menu*/
.navbar-light .navbar-nav .active a::after {
  border-bottom: 3px solid $primary_color;
  bottom: 7px;
  width:62px;
  content: " ";
  left: 0;
  position: absolute;
  right: 0;
  /*color: #340EF5;*/
}

.navbar-inverse{
   background: #ef5500;
   border: 3px solid #340ef5;
   margin-bottom: 0px; 
   


}


.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    /* color: rgba(0,0,0,.7);*/
    color:$sub-color;
    
    }


.navbar-nav>li>a {
 color : #340EF5;
}

.dollsandbags{
  background-image: url("assets/dollsandbags.png");

}

.guest-form-card h1{
  text-transform: uppercase;
  font-size:medium;
}

.guest-form-card label{
  font-size: small;
}




.bg-iconnav {
  background: #D30169;
}




.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #EF5500;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}


.navborder{
 
 // border:1px solid; 

  border-top: 1px  #36013F solid;
 // width:auto;
  //color: #36013F;
  /*border-image: conic-gradient(#349299, #340EF5) 1; */
}

sp-hr {
  border-color:gray;
  height: 0px !important;
  max-width: 0px;
}

  
/*This is so I can have two nav bar  rows*/
.navbar-collapse {    /*This for first nav*/
    float: left;
    width: 100%;
    clear: both;
 
} 

.bg-iconnav {        /*change color of hamburger*/
   background: $primary-color;

}


.dropdown {
    cursor:pointer;
}


 #form-master {
position: relative;
width: 200px;
height: 30px;
}

.btn{
  width: 100px;
  font-size:8px;
}

@mixin border-radius($radius){
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius:$radius;
  border-radius: $radius;

}

.cust-clear-btn{
  @include border-radius(7px);
}

 .search-anywhere{
      margin: 0px -90px 0px 0px;
     
 } 
    .anywhere-search{
      /*background-color: #526982;*/
      border:1px solid #340EF5;
      padding-right: 17px;
      height:34px;
      width: 150px;
      border-radius:6px;
      margin-top:8px;
    
    }
    
    
    .isearch {
      float: right;
      margin-right: 6px;
      margin-top: -20px;
      position: relative;
      z-index: 2;
      color: #FFFFFF;
        }
    
    .search-pos{
       margin-top:-26px;
       color: $primary-color;
    }
    
    .usersearch{
      float: right;
      margin-right: 6px;
      margin-top: -20px;
      position: relative;
      z-index: 2;
      color:#AAAFB9; 
         }

    .searchuser-byinput{
       border-radius:6px;
       height:29px;
       width:250px; 
       border:1px solid #AAAF89; 
       outline: 0;
       padding-bottom: 7px;

    }

    .search-box{

      display:block;
      margin: 10px auto 0 auto;
      padding: 10px 20px;
      max-width:550px;
      width: 95%;
    }


.fa-search:hover{  /*this is for hover on search box icon*/
          cursor:pointer;
          
           }

  input[type="search"]::-webkit-search-decoration:hover,
        input[type="search"]::-webkit-search-cancel-button
         { 
        cursor:pointer; 
        
    }


    input[type="search"]::-webkit-search-cancel-button{

      position:relative;
        right: 5px;
    }

    

/* For Internet Explorer */
input[type=search]::-ms-clear{
  margin-right: 20px;      /*This should position the X*/
  

}
input[type=search]::-ms-clear{
  cursor:pointer; 
  cursor: auto;   /*Similar to when hover over text*/

}

/**************hack for clicking on white space prevent radio button from being clicked*/
.hiddenInput { display: none; }



/* This is temporary*/

.search-box{
  display:flex;
  margin: 40px auto 0 auto;
  padding: 10px 20px;
  max-width: 400px;
  width: 95%;
}

hr{
  border:none;
  height: 1px;
  width: 200px;
  background-color: lightgrey;
  padding-bottom: 0;
}


.search-result{
  /*max-width: 400px;*/
  width: 400px;
/*margin: 50px auto 0 auto;*/
  hr{
    border:none;
    height: 1px;
    background-color: lightgrey;
   
  }

  p{
    margin: 10px 0 0 -100px;
  }
}

/*.test{
  background-color:lightgray;
}*/

.someborder{
   border: solid 3px #349299;
 
}

.img-circle {
    border-radius: 50%;
    
    background: #fcfbfb;
    color: #349299;
} 

.fa_custom {

color: #03a9f4;
}   


 /*small borderbox at top of footer*/
.borderBox{
    box-sizing: border-box;
    height: auto;
    width: 100%;
    background: $sub-color;
  
}

.checkout-border{
  margin-left: 50px;
  border: 1px solid #007BFF

  
}



.checkout-borderBox{
  box-sizing: border-box;
    height: auto;
   
    border: 2px solid #349299   

}

/*******************Login*******************/

.card-style{
  margin-top:25px
}
.pos{
  text-indent: 20px;
}

.fa-username{
  position:absolute; 
  top: 115px; 
  left:25px; 
  color: #007BFF 

}

.fa-password{
  position:absolute;
  top: 200px;
  left:25px;
  color: #007BFF
}

.fa-email{
position:absolute;
top: 290px;
left:25px; 
color:#007BFF
}

.login-cancel{
  background-color:#D30169; 
  color:#FFFFFF

}

.forgot-password-link{
  font-size: 10px;
  margin-left: -20px

}

/***********************Register************/


/***********************Forgot Password******/

.card-style{

  margin-top: 25px;

}

.fa-mail{
  position:absolute;
  color:#007BFF;
  top:235px; 
  left:25px; 
  

}

/*********************Register**************/

.fa-reg-fn{
  position:absolute;
  color:#007BFF;
  left:25px;
  top:115px

}

.fa-reg-ln{
  position:absolute;
  color:#007BFF;
  left:25px;
  top:200px

}

.fa-reg-un{
  position:absolute;
  color:#007BFF;
  left:25px; 
  top:289px

}

.fa-reg-pw{
  position:absolute;
  color:#007BFF;
  left:25px; 
  top:375px


}

.fa-reg-fpw{
  position:absolute;
  color:#007BFF;
  left:25px;
  top:460px

}

.fa-reg-em{
  position:absolute;
  left:25px; 
  top:545px;
  color:#007BFF

}



/********************CartItem***************/

.cartitem-img{
  /*padding-bottom:30px;
  padding-top:50px;*/
  margin-bottom: 100px;
  padding-right:20px

}

.cartitem-card{

  width:1vw;
  margin-top: 10px

}

.cartitem-right{
  margin-top: 20px

}

.cartitem-can{

  color:#D30169;
  cursor: pointer;
  font-size:2vw;
  margin:0 0
}

.cartitem{
  font-size:1vw;
  width: 400px;
 
}

.cartitem-price{
  font-size: 13px;
  width:400px;
  padding-bottom:15px;
}

.cartitem-qty{

  top:10px;
  font-size:13px;
  padding-bottom: 10px;
}

/********************ProductItem***************/

.productitem-container{
  
  height:100%;
  width:300px

}

.productitem-row{
 
  margin-top: 20px;

}

.productitem-para{
  text-align:left;
  margin-left:-2px;
  width:140px;
  margin-top:20px;

} 

.productitem-form{
  
  font-size:11px;
  text-align:left; 
  margin-left: -25px;
  width:200px


}

.productitem-label{
  padding: 4px

}

.productitem-text{

  text-align:left;
  width: 150px


}

.productitem-price{
  text-align:left;
   width:100px;

}

.productitem-sub-btn{

  border:0;
  outline:0; 
  background-color:transparent;
  padding-bottom: 100px;
  margin-left: -13px;
  color:#d30169


}

.productitem-img{

  background-color:none;
  color:#D30169;
  font-size:5px;
  cursor:pointer; 
  outline:none; outline:0;
  border:0;


}

.product-details{
  text-align:left;
   width: 150px
}



/************************Wishlistitem*********************/

.wishlistitem-container{

  height:100%; 
  width:300px;

}

.wishlistitem-row{
  margin-top: 20px;

}

.wishlistitem-card-text{

  text-align:left;
  width:130px;
  margin-left: -10px;
  margin-top:20px


}

.wishlistitem-card{
  text-align:left;
  width:130px;
  margin-left: -10px;
  font-size: 11px;

}

.wishlistitem-card-tip{

  text-align:left;
  width:130px;
  margin-left: -10px;
  cursor: pointer;
  font-size:11px
  
}

.wishlistitem-card-font{
  font-size:11px

}

.wishlistitem-description{

  text-align:left;
  width: 150px; 
  margin-left: -10px;
  cursor: pointer;

}



.wishlistitem-remove{

  font-size: 12px;
  width: 30px; 
  margin-left: -10px; 
  color: #d30169;
  white-space:nowrap;
  padding-bottom: 25px;
  border:0;cursor: pointer
}

.wishlistitem-trash{

  margin-left:5px; color:#d30169; padding-bottom:70px; margin-top:-300px

}

/**************************Wishlist-List***************/

.wishlistlist-hl{

  color: #D30169;
  font-size: 2vw;
  text-align:center;
  margin-bottom:50px

 

}


.wishlistlist-container{

  height:500px;

}

/**************************Productlist*********************/

.productlist-p{
  text-align:center;
  color: #D30169;
  font-size: 1vw;
  font-weight: bold; 

}

.productlist-img{
  height: 50px;
}

/***************************SaleList***************/

.salelist-h1{
  text-align:center;
  color:#D30169; 
  font-size:2vw

}

/***************************Footer*****************/

.footer-h1{
  color: #ffffff;
  font-size: 2vw;
  margin-left: -40px;

}


.footer-h1-2{  
  color: #ffffff;
  font-size: 2vw;
  margin-top: 60px;
  margin-left:-120px

}

.footer-ul{
  
  list-style-type:none;
  margin-left:-30px;

}

.footer-modal{
  margin-left: 50px;

}

.footer-form{
  margin-left: 50px;
  
  
}

.footer-copyright{
  color:#ffff;
  font-size: 1vw;
  padding-bottom: 10px;
  margin-left: 590px;
  width: 500px

}

.footerStyle{
  color: #bbf2f5;
  font-size: 1vw;
  list-style-type:none;

}

.footer-ui-style{
 margin-top: 70px;
 margin-left: 230px;

}



footer .centered-on-small{
  text-align: center;
   }
* {box-sizing: border-box;}


/**********************Header******************/
/*take a look at this*/
.header-img-logo{
 /* width:300px;
  margin-bottom: 7px;*/
  width:100%;
  margin-top:-30px; 

}

.header-form-pos{
  width: 900px;  padding-left: 37px; font-family:5px; border-radius:6px; padding-bottom: 20px

}

.header-search{
  width:200px;
  margin-bottom: 7px;

}

.header-ng-template{
  width: 300px;
  border: 2px solid lightgrey;
  height:auto;
  position:relative;              
  border-radius:6px;
  z-index:5;
}

.header-para{
 cursor: pointer;
}

.header-itemcart{
  padding-left: 30px;
  cursor: pointer;

}

.header-circle{
  font-size: 25px;
  margin-top: -20px;
  cursor:none;
  color:$primary-color;

}

.header-itemcart-strong{
  color:#000000;
  margin-left: -16px;
  padding-top: 0;
  font-size:12px; 
  line-height: 20px;

}

.header-btn-register{
  height: 35px;
  width: 60px;
  margin-left:20px;

}

.header-btn-a-register{
  margin-left: -20px;
  margin-top:-6px;
  font-size:12px;
  color:#ffffff;
}

.header-btn-login{
  height: 35px;
  width: 60px; 
  background-color: #D30169;
  margin-left:5px;

}

.header-btn-a-login{
  margin-left: -15px;
  margin-top: -6px;
  font-size:12px; 
  color:#1D004F; 
  border:none;

}

.header-btn-dropdown-user{
  font-size: 20px;
  color: #1D004F;
  cursor:pointer; 
  
  border:none;
  outline: none;
  background-color: transparent;

}


.header-d-menu{
 min-width: 10px;
 /*margin-left: -30px*/

}

.header-fs-user{
  cursor:pointer;
  color:#006DE2;
  margin:0;
  padding:0
 /* margin-left:50px;*/
 

}

.header-modal-header{
  margin-left: 450px

}


.header-row{
  margin-top: 40px;
  margin-left: -500px;

}

.header-col-para{
  text-align:left;
  margin-left:-2px;
  width:140px;

}

.header-form-para{
  text-align:left;
  margin-left: 100px;
  margin-top: 2px; 
  width:100px;

}

.header-text-center{
  margin-top: -10px

}

.headerlist-style{
  list-style-type: none;


}


.header-img{
  margin-bottom: 10px;
  background-position:center;
  background-image: url('assets/shopping-cart-filled-with-colorful-items-floats-vibrant-abstract-dreamlike-landscape_34950-23710.jpg');
  background-repeat: no-repeat; 
  height: 300px;
  z-index: 20

 /* width:60%;
  height: 400px;*/

}

.heart-button{
  border-color:transparent;
  outline: none; 
  background-color: transparent;


}

.go-first {
  z-index: 21;
}

.heroContent {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(241, 240, 240, 0.4);
  text-align: center;
  font-size:1vw;
  z-index: 10
}



/*-------------------------Layout******************/
.comp-layout{
  margin: 0 auto;
  width:80%; 
 // height:80%;

  margin-bottom:-65px;
  padding-bottom: 65px;
 
  //background-repeat:no-repeat

}

.comp-layout-image{
  background-image: url('assets/HomePage2-1-Final.png');



}

.complayout-text-container {
  position: absolute;
  //color:6rgb(255, 255, 255);
  color:#ffffff;
  left: 220px;
  top: -100px;;
  width:500px;
  height: 300px;
  transform: translate(-50%, -50%);
  background-color: rgba(41, 41, 41, 0.4);
  padding: 0.5rem;
  text-align: center;
  font-size: 16px;
}


/**************************************************/
.h1-contactus{

  font-size: 2vw;
   margin: 0 auto;
   padding-bottom: 60px; 
   text-align: center;
   margin-top: 20px;
   color:#006DE2; 
 
 }
 
 
 .h1-contactus-color{
 
   color:#006DE2
 
 }

 .something{
  color:pink;

 }


 .contactus-size2{

  font-size:1vw
}

.custom-jumbotron{
  background-color:white !important

}

.comtactus-contain{
  margin-top:  10px 

}



/*******************************Contact US***********************/
.animated-position{
   
 
  padding-bottom: 10px;

 }

/*.header*/

.box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

 .cart-borderBox{
  box-sizing: border-box;
  margin: 0 auto;
  /*height: auto;*/
  width: 60%;
    
}

.header-navbar{
  font-size: 18px;
  cursor:pointer;

}



.validation{
  background-color: red;
    padding: 1em;
    font-weight: bold;
    width: 50%;
    margin: 1em 0;
}

.inside-bB{
  border: 3px solid $primary-color;
  padding: 50px;
  margin:0 auto;
  border-radius: 8px;
}


.twitter{
   color: $base-color;
   margin:auto;
   font-weight: bold;
  } 
/*hold
 .aos-animate .image-wrap .lazyloaded:not([role="presentation"]), .aos-animate .image-wrap svg, .aos-animate .image-wrap .animate-me, .aos-animate .image-wrap img[data-modal-lazy] {*/

 .facebook{
   color: $base-color;
   margin-right: 2px;
  
}

.instagram{
   color:$base-color;

}

.socialmedia-col{
   color: $base-color;
   font-size: 18px;
  

} 
 


footer {
  position: relative;
  left: 0;
  top: 10px;
  bottom: 0;
  height: auto;
  width: 100%;
  overflow: hidden;
}

.wrapper {
  width: 820px;
}

.container-2col-nm {
    display: flex;
    justify-content: space-between;
}

.container-2col-nm > div {
    margin: 0;
    padding: 0;
    text-align: left;
}



 .customFooter{
 /* background: $primary-color;*/
 /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff5db1+0,ef017c+100;Pink+3D+%231 */
background: #ff5db1; /* Old browsers */
background: -moz-linear-gradient(top,  #ff5db1 0%, #ef017c 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #ff5db1 0%,#ef017c 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #ff5db1 0%,#ef017c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5db1', endColorstr='#ef017c',GradientType=0 ); /* IE6-9 */

  height: 100%;
  width: 100%; 
  margin: 0;
 
}



#footer{
   color: $footer-misc;
     /*width: 100%;*/
  /*padding: 50px 0 40px;
    margin-bottom: 30px;*/
}


#copyrightFooter {
    color: black;
    color: $footer-misc;
    line-height: 30px;
    height:100%;
    width: 100%;
    margin:0;
}

#backToTopLink::after {
    display: block;
    content: "";
    width: 15px;
    height: 15px;
    border-left: 1px solid $footer-misc;
    border-top: 1px solid $footer-misc;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 50%;
    top: 13px;
    margin-left: -9px;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}



 
/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
  background-color: #ffffff;
  color: $primary_color;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  /*opacity: 0.8;*/
  position: absolute;
  bottom: 23px;
  right: 28px;
  width: 100px;
  border-radius: 3px;
}

/* The popup chat - hidden by default */
.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
}

   .pop-form-pos{
    border-radius: 6px;
    border: 1px solid #AAA489;
    width: 225px;
    height: 27px;
    margin: 2px 0px 15px 0px;
    font-family:'Open Sans';
    font-size: 13px; 
   
   }

   .myCustomeModalClass .modal-dialog{
    max-width:565px;
    height: 400px;
    margin-top: 80px;

   }

/* Add styles to the form container */
/*.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}*/

/* Full-width textarea */
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #4CAF50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}

.img-fluid{

  max-height: 100%;
}

.row>.col{
  flex-basis:auto ;
}


/*This is so I can have two nav bar  rows*/
.navbar-collapse {    /*This for first nav*/
    float: left;
    width: 100%;
    clear: both;
} 


.cursor-disabled{
  cursor: not-allowed
} 

.navbar-customcolor{
  background-color: $primary-color;
}


.loggedin-user{
 // margin-top: -22px;
  margin-left: 10px;
  font-size: 11px;
  color:#D30169;

}

.loggedin-user-font{
  color:#D30169;

}



.custom-position{
  

  margin-top: -90px;
}


.backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 380px;
  bottom: 0;
  background: black;
  opacity: 0.5;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(0,0,0,0.5);
}

/********************************About Us***********************/
.about-img{

  width: 60%; 
  display:block;
  margin-left:auto;
  margin-right:auto;
}

.aboutus-h1{
  text-align:center;
  color:#d30169;
  font-size: 1vw

}

.about-container{
  margin-top: 10px;
  min-height: 100vh;

}

.aboutus-h2{
  font-size: 14px;
  color:#d30169; 
  text-align: center;
  margin-top:-20px

}


.aboutus-h3{
  font-size: 14px;
  color:#d30169;
  text-align: center;
  margin-top:-20px

}

.aboutus-h4{
  font-size: 14px;
  color:#d30169;
  text-align: center;
  margin-top: -20px

}

.about-row{
  display: flex; justify-content: center;

}

/*.about-background-img{
  background-image:url('../../../../assets/My-Deniseia_Croped_1_50.jpg'); height:594px; width:585px; display:block; margin-left:auto; margin-right:auto;

}*/

.about-col1{
  border:2px solid pink;
  padding:10px; 
  border-radius: 40px 20px;
  font-size: 1vw;

}

.about-col2{
  border:2px solid pink;
  padding:10px;
  border-radius: 40px 20px;
  margin-left:25px;
  font-size: 1vw


}

.about-col3{
  border:2px solid pink;
  padding:10px;
  border-radius: 40px 20px;
  margin-left:25px;
  font-size: 1vw

}


.about-img1{
  margin-left:-75px;
  margin-top: -90px

}

.about-img2{
  margin-left:-75px;
  margin-top: -90px

}

.about-img3{
  margin-left:-75px;
  margin-top: -90px

}

/*********************************Checkout**********************/
.checkout-contaner{

  margin-bottom:30px;
}

.choosemethof-pay{
  color:#009cde;
  font-size:2vw

}

.pp-button-container{
   
  margin-top: 100px


}

.button-ppcontainer{

  margin-top: 100px;
}

.checkout-itemcart-route{
  cursor: pointer;
  text-decoration:underline;
  margin-left: 100px;
  font-size: 1vw;

}

.checkout-ul{
  font-size:1vw;
  padding-top: 30px;
  padding-left:0px;
  padding-bottom:0px

}



/***************Cart Component**********************/

  .cart-list-item{

    margin-top:30px;
    font-size: 1vw;

  }

  .cart-cont{

    margin-bottom:20px
  }

  .cart-list-size{
    font-size: 1vw
  }

  .cart-list-h1{
    font-size:2vw;
    text-align: center;
    color:#D30169

  }

  .cart-bbprimary{
    
    height: 40px;
    width:210px;
    margin-top:20px;
    color:ffffff

  }

  .cart-prod-route{

    color: #ffffff;
    cursor: pointer;
    font-size: 1vw
  }

  .cart-check-route{
    color: #ffffff; 
    font-size:1vw
  }


  .cart-bbprimary2{

    height: 40px;
    width:210px;
    margin-top: 20px;
    background-color:#D30169


  }


/*****************************************/


 
/*Make sure this is doing what I expect*/
@media (min-width: 768px) {
    .navbar-right {
     margin-top: -50px;
      padding: 0 15px;      
    }
} 
@media (max-width: 576px) {
  .responsive-content {
    font-size: 6vw;
  }
}

@media (max-width: 576px) {
  .responsive-content {
    font-size: 6vw;
  }
}




/*-------------------------------------*/

/* View Port Fix*/
/*@-webkit-viewport{width:device-width}
@-moz-viewport{width:device-width}*/
@-ms-viewport{width:device-width}
@-o-viewport{width:device-width}
@viewport{width:device-width}
   


/* Importing Bootstrap SCSS file. */
/*@import '~bootstrap/scss/bootstrap';*/

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
